import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the shinrai and by choosing the right plan. Now I am in Japan working as a program manager.",
    name: 'MATHEW HENDRICKSON',
    status : 'Manager'
  },
  {
    image: image2,
    review: 'I can not express my gratitute towards Shinrai. It changes my whole career. ',
    name: 'JOHN KEVIN',
    status: 'ENTREPRENEUR'
  },
  {
    image : image3,
    review:'I am working with Shinrai for quiet a long time and I can surely say that it is one of the finest institute that bring smile to many peoples',
    name: 'FRANKLIN',
    status: "SOCIAL WORKER"
  }
];
